// enable section snapping on home
document.arrive('body.home', { existing: true }, function(el) { el.classList.add('snappy') })

// observe sections and set required classes to highlight photos, etc.
function observeSection(key, dim_all_photos = false, activate_one_photo = false, threshold = 0.5, opaque_header = false){
  const body = document.querySelector('body')
  const header = document.querySelector('header')
  const options = { root: body, threshold: threshold }
  const section = document.querySelector(`section#${key}`)
  const section_bg = document.querySelector('section#bg')
  const bub = document.querySelector('.bubble.rec296')

  // these are collections - photos are rendered separately for mobiles
  const photo_boxes = document.querySelectorAll('.team-photo')
  const images = document.querySelectorAll(`img.${key}`)

  const callback = (entries) => {
    entries.forEach((entry) => {
      // section is visible
      if (entry.isIntersecting) {
        // update left-side navigation
        document.querySelectorAll('.slider-navi-item').forEach(e => e.classList.remove('active'))
        document.querySelectorAll(`.slider-navi-item.${key}`).forEach(e => e.classList.add('active'))
        // update header opacity
        if (opaque_header) { header.classList.add('opaque') }
        else { header.classList.remove('opaque') }
        // add indicator class on #bg
        if (section_bg) {
          section_bg.removeAttribute('class')
          section_bg.classList.add(key)
        }
        if (key == 'get-in-touch') {
          // hide slider navi on get-in-touch section
          document.querySelector('.slider-navi').classList.add('opacity-0')
        }
        if (key == 'team') {
          // hide bg photos, hide annoying bubble
          photo_boxes.forEach((el) => el.querySelectorAll('img').forEach((img) => img.classList.add('opacity-0')))
          if (bub) { bub.classList.add('opacity-0') }
        }
        if (['top', 'experience', 'network', 'process', 'altopartners'].includes(key)) {
          // reveal bg photos on sections where needed
          photo_boxes.forEach((el) => el.querySelectorAll('img').forEach((el) => el.classList.remove('opacity-0')))
        }
        if (dim_all_photos) { photo_boxes.forEach((el) => el.classList.add('dimmed')) }
        else { photo_boxes.forEach((el) => el.classList.remove('dimmed')) }
        if (activate_one_photo && images.length) { images.forEach((img) => img.classList.add('active')) }
      }
      // section is not visible
      else {
        if (key == 'get-in-touch') {
          if (document.querySelector('.slider-navi')) {
            document.querySelector('.slider-navi').classList.remove('opacity-0')
          }
        }
        if (key == 'team') {
          if (bub) { bub.classList.remove('opacity-0') }
        }
        images.forEach((img) => img.classList.remove('active'))
      }
    })
  }
  const observer = new IntersectionObserver(callback, options)
  if (section) { observer.observe(section) }
}

document.arrive('body.home:not(.cached)', { existing: true }, function(el) {
  // Prague
  observeSection('top', false, false, 0.5, true)
  observeSection('experience', true, true, 0.5, true)
  observeSection('network', true, true, 0.5, true)
  observeSection('process', true, true, 0.5, true)
  observeSection('altopartners', true, true, 0.5, true)
  // Common
  observeSection('team', false, false, 0.3, true)
  observeSection('figures', false, false, 0.9)
  observeSection('references', false, false, 0.9)
  observeSection('blog', false, false, 0.8)
  observeSection('get-in-touch', false, false, 0.3)
  // Warsaw
  observeSection('who-we-are', false, false, 0.5, true)
  observeSection('how-we-work', false, false, 0.5, true)
  observeSection('services', false, false, 0.5, true)
  observeSection('leading-by-example', false, false, 0.5, true)
})

document.arrive('.slider-navi-link', { existing: true }, function(el) {
  el.addEventListener('click', function(evt){
    evt.preventDefault()
    document.querySelectorAll('.slider-navi-item').forEach(e => e.classList.remove('active'))
    el.querySelector('.slider-navi-item').classList.add('active')
    let anchor = el.href.split('#')[1]
    document.querySelector('#' + anchor).scrollIntoView({behavior: 'smooth'})
  })
})

// prevent animations to be played twice (when page is restored from cache)
document.addEventListener('turbo:before-cache', (evt) => {
  document.querySelector('body').classList.add('cached')
})
