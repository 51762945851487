import { Controller } from '@hotwired/stimulus'

export default class extends Controller {

  connect() {
    if (localStorage.getItem('cookie-bar-acknowledged')) {
      this.element.classList.add('d-none')
    }
  }

  acknowledge(event) {
    event.preventDefault()
    event.stopPropagation()
    localStorage.setItem('cookie-bar-acknowledged', true)
    this.element.classList.add('d-none')
  }

}
